

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components{
    .btn{
        @apply text-white bg-gradient-to-br hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-kGreen-light dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2
    }

    .btn-out{
      @apply  border font-medium rounded-lg focus:ring-4 text-sm px-5 py-2.5 text-center mr-2 mb-2 focus:outline-none
    }
    .btn-out-pri{
      @apply text-kGreen-light border-kGreen-light hover:bg-kGreen-dark  hover:text-white  focus:ring-kGreen-light dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800
    }
  
    .txtinp{
      @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
    }
    .flabel{
      @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400
    }
    .fdropdown{
      @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500-500 dark:focus:border-green-500
    }
    .ftxt{
      @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500
    }
    .fradio{
      @apply w-4 h-4 text-purple-600 bg-gray-100 border-gray-300 focus:ring-kGreen-light dark:focus:ring-kGreen-light dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600
    }
    .fradio-label{
      @apply ml-2 text-sm font-medium text-gray-900 dark:text-gray-300
    }

}



.rc-slider-handle {
    position: absolute;
    width: 7px;
    height: 4px;
    margin-top: -5px;
    background-color: #fff;
    border: solid 1px #008081;
    border-radius: 50%;
    cursor: pointer;
    cursor: grab;
    border: none;
    opacity: 0.8;
    touch-action: pan-x;
}


.cover-back {
    
    /* background: linear-gradient(11.16deg, rgba(1, 128, 129, 0.2) -25.46%, rgba(1, 128, 129, 0) 46.57%, rgba(1, 128, 129, 0.27) 144.63%); */
    border-radius: 0px 0px 60px 60px;  
}

.cresent{
    width: 89px;
    height: 24px;
    /* left: 56px;
    top: 231px; */
   
.cus-border{
  background: linear-gradient(90deg, rgba(239, 248, 245, 0) 0%, #C5C5C5 48.96%, rgba(239, 240, 248, 0.0204082) 99.99%, rgba(239, 240, 248, 0) 100%);
  border-radius: 2px;
}
/* abc */

    background: linear-gradient(180deg, #008081 61.98%, #645faa 100%);
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
}

.cresent-shape{
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.scroll-hide::-webkit-scrollbar {
  display: none;
}

.scroll-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 2.2em;
  font-weight: 100;
  color: #555;
  user-select: none;
}

.scrollable-div {
       background-color: #f1f1f1;
        overflow-y: scroll;
        white-space: nowrap;
        margin: 20px;
        text-align: justify;
        padding: 20px;
}

.rc-slider {
	&-rail {
		background-color: #008081;
	}

	&-track {
		transition: all 0.3s ease-in-out;
		background-color: #008081;
		visibility: visible !important;
	}

	&-dot {
		width: 9px;
		height: 9px;

		background-color:#008081;
		border: none;
	}

	&-dot-active ~ .rc-slider-dot {
		background-color: #008081;
	}

        &-mark {
		font-size: 0.8rem;
		color: #008081;
		font-weight: 700;

		&-text-active  {
			color: #008081;
			font-weight: 800;
		}
	}

	&-handle {
		border: 3px solid color(tertiary);
	}
}
/* input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 50px 0;
  }
  input[type=range]:focus {
    outline: none;
  } */
 
  
 /* input[type=range]:focus::-webkit-slider-runnable-track {
    background: #367ebd;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    background: #3071a9;
    border-radius: 1.3px;
    border: 0.2px solid #010101;
  }
  input[type=range]::-moz-range-thumb {
    height: 50px;
    width: 50px;
    border-radius: 3px;
    background-color: transparent;
    background: url(https://i.stack.imgur.com/QneFV.png) center center no-repeat;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -23px;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 0.2px solid #010101;
    border-radius: 2.6px;
    box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    height: 50px;
    width: 50px;
    border-radius: 3px;
    background-color: transparent;
    background: url(https://i.stack.imgur.com/QneFV.png) center center no-repeat;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -23px;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  } */
input[type=range]::-webkit-slider-thumb {
	
    background-image: url('https://ej2.syncfusion.com/demos/src/slider/images/thumb.png');
    background-color: transparent;
    height: 24px;
    width: 25px;
    -webkit-appearance: none; 
}
  /* @apply w-6 h-6 appearance-none pointer-events-auto; */
 /* }

/* .e-control.e-slider .e-handle {
    background-image: url('https://ej2.syncfusion.com/demos/src/slider/images/thumb.png');
    background-color: transparent;
    height: 25px;
    width: 25px;
} */
/* input[type="range"] {
    -webkit-appearance: none;
    width:100%;
    background-image: linear-gradient(#008080, #006060);
    border-radius:5px
    /* margin-right: 15px;
    width: 100;
    height: 7px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    background-image: linear-gradient(#ff4500, #ff4500);
    background-size: 70% 100%;
    background-repeat: no-repeat; */


  /* input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #008080;
    cursor: ew-resize;
    box-shadow: 0 0 2px 0 #555;
    transition: background .3s ease-in-out;
  } */
  